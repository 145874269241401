/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from "reactstrap";

import "./addressSearch.css";

import { Redirect } from "react-router-dom";

import queryString from "query-string";
import geoLocation from "services/geoLocation";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../components/Notify";
import Loader from "components/Loader";
import api from "services/backendService";
import keys from "configs/constants";
import utils, { checkMac } from "utils";
import { withTranslation } from "react-i18next";
import { AuthorizationContext } from "../contexts/AuthorizationContext";

class RegisterThing extends React.Component {
  static contextType = AuthorizationContext;
  constructor(props) {
    super(props);
    this.state = {
      zoom: 7,
      address: "",
      duid: "",
      sites: [],
      customers: [],
      selectedSite: {},
      redirect: false,
      gatewayName: "",
      deviceAdded: false,
      loading: true,
      city: "",
      country: "",
      sitename: "",
      selectedCustomerGuid: "",
      latitude: "",
      longitude: "",
      geocode: "",
      geocodeList: [],
      availableLicense: [],
      selectedLicenseName: "",
      canRegisterNewGateway: true,
      dropdownOpen: false
    };
    this._rate_limiter = null;
    this._watchID = null;
    this.onSiteSelected = this.onSiteSelected.bind(this);
    this.addGatewayClicked = this.addGatewayClicked.bind(this);
    this.getLicenses = this.getLicenses.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onInputChange(evt) {
    const target = evt.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
    if (name === "geocode") {
      const t = this;
      if (value.length > 2) {
        clearTimeout(this._rate_limiter);
        this._rate_limiter = setTimeout(() => {
          geoLocation.searchGeoLocation(value).then((results) => {
            t.setState({ geocodeList: results.data });
          });
        }, 500);
      } else {
        t.setState({ geocodeList: [], latitude: "", longitude: "" });
      }
    }
  }

  onLocationSelected(loc) {
    const adrinfo = utils.geocodeReverseAddress(loc.address);
    this.setState({
      geocode: loc.display_name,
      latitude: loc.lat,
      longitude: loc.lng,
      address: adrinfo.city + ", " + adrinfo.country,
      country: adrinfo.country,
      city: adrinfo.city,
      geocodeList: [],
    });
  }

  onLicenseSelected(licenseName) {
    this.setState({
      selectedLicenseName: licenseName,
    });
  }

  getAvailableLicenseForSelectedCustomer = async (customerGuid) => {
    const api_pload = (
      await api.getLicensesByListOfSubscriptionGuids([customerGuid])
    ).data.data;

    let licenseList = {};
    for (const license of api_pload) {
      if (license.isActive === 0 && license.activeFrom === null) {
        const now = new Date();
        let gap = new Date();
        gap.setDate(now.getDate() + license.duration);
        const duration = utils.getAge(now, gap);
        const licenseName = JSON.parse(license.content).name + " " + duration; // Examples: Haccp 1 Year - Basic 2 Years
        if (licenseName in licenseList) {
          licenseList[licenseName]["count"]++;
          licenseList[licenseName]["list"].push(license.licenseGuid);
        } else {
          licenseList[licenseName] = {
            count: 1,
            list: [license.licenseGuid],
            name: licenseName,
          };
        }
      }
    }

    let a = [];
    for (const k in licenseList) {
      a.push(licenseList[k]);
    }
    return a;
  };

  async onCustomerSelected(customerGuid) {
    const license =
      await this.getAvailableLicenseForSelectedCustomer(customerGuid);
    this.setState({
      selectedCustomerGuid: customerGuid,
      availableLicense: license,
    });
  }

  async onSiteSelected(siteGuid) {
    const selSite = this.state.sites.filter(
      (site) => site.siteGuid === siteGuid
    );

    if (siteGuid !== 0 && selSite.length === 1) {
      const license = await this.getAvailableLicenseForSelectedCustomer(
        selSite[0].customerGuid
      );
      this.setState({
        selectedSite: selSite[0],
        sitename: selSite[0].name,
        selectedCustomerGuid: selSite[0].customerGuid,
        availableLicense: license,
      });
    } else {
      this.setState({
        selectedSite: { siteGuid: 0 },
        sitename: "",
        selectedCustomerGuid: "",
        selectedLicenseName: "",
      });
    }
  }

  notifyFormWithErrors() {
    notifyStore.addNotification({
      ...tempOptions,
      content: NotifyContent(
        "danger",
        null,
        this.props.t("registerThing.fieldError")
      ),
    });
  }

  async getLicenses() {
    if (this.state.selectedCustomerGuid !== "") {
      const license = await this.getAvailableLicenseForSelectedCustomer(
        this.state.selectedCustomerGuid
      );
      this.setState({
        availableLicense: license,
        selectedLicenseName: "",
      });
    }
  }

  async addGatewayClicked(event) {
    this.setState({ loading: true });
    const duid = this.state.duid.toUpperCase();
    let api_pload;
    // TODO: Uncomment the following lines once Promotional period is ended
    // let licenseGuidList = [];
    // let lGuid = null;
    event.preventDefault();

    const newgatewayName = this.state.gatewayName;

    // TODO: Uncomment the following lines once Promotional period is ended (between ====)

    // Get full list of licenseGuids that were expected to be available
    // once the user selected the license type (Haccp/Basic/etc)
    // ====================================================================================
    // for (const l of this.state.availableLicense) {
    //   if (this.state.selectedLicenseName === l.name) {
    //     licenseGuidList = l.list;
    //     break;
    //   }
    // }
    // if (licenseGuidList.length === 0) {
    //   utils.debug("This is an issue. No license available");
    //   return;
    // }

    // lGuid = licenseGuidList[0]; // To be verified with code above instead of using hardcoded first guid
    // ====================================================================================

    if (this.state.selectedSite.siteGuid === 0) {
      api_pload = (
        await api.addGateway({
          macAddress: duid.toUpperCase(),
          name: newgatewayName,
          telemetryPushInterval: "300",
          // TODO: Uncomment the following line once Promotional period is ended
          // licenseGuid: lGuid,
          customerGuid:
            this.state.customers.length > 1
              ? this.state.selectedCustomerGuid
              : this.state.customers[0].customerGuid,
          newSite: {
            name: this.state.sitename,
            country: this.state.country,
            city: this.state.city,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            customerGuid:
              this.state.customers.length > 1
                ? this.state.selectedCustomerGuid
                : this.state.customers[0].customerGuid,
          },
        })
      ).data;
    } else {
      api_pload = (
        await api.addGateway({
          macAddress: duid,
          name: newgatewayName,
          telemetryPushInterval: "300",
          siteGuid: this.state.selectedSite.siteGuid,
          customerGuid: this.state.selectedSite.customerGuid,
          // TODO: Uncomment the following line once Promotional period is ended
          // licenseGuid: lGuid,
        })
      ).data;
    }

    if (api_pload.gatewayGuid) {
      this.setState({
        deviceAdded: true,
        loading: false,
        newGatewayGuid: api_pload.gatewayGuid,
        siteGatewayGuid: api_pload.site.siteGuid,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  async componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const validMac = utils.isMACAddressValid(queryParams.duid);

    const canRegisterNewGateway = this.context.canRegisterNewGateway();
    this.setState({ canRegisterNewGateway });

    let message = "valid";

    switch (validMac) {
      case checkMac.INVALID_LENGTH:
        message = "registerThing.invalidLength";
        break;
      case checkMac.INVALID_CHARS:
        message = "registerThing.invalidChars";
        break;
      case checkMac.INVALID_OUI:
        message = "registerThing.invalidOui";
        break;

      default:
        break;
    }

    if (validMac === checkMac.VALID_MAC) {
      // Proper MAC address has length = 12
      const alreadyRegistered =
        (await api.getGatewayByMAC(queryParams.duid.toUpperCase())).data.total >
        0;
      if (alreadyRegistered) {
        this.setState({ redirect: true, loading: false });
      } else {
        const geoLocationEnabled = this.addGeoLocation();
        let all_sites = [];
        let c_pload = [];
        try {
          c_pload = (await api.getCustomersDetails()).data.data;

          for (const c of c_pload) {
            for (const s of c.sites) {
              all_sites.push({
                name: s.name,
                siteGuid: s.siteGuid,
                customerGuid: c.customerGuid,
              });
            }
          }
        } catch (e) {
          utils.debug(JSON.stringify(e));
          c_pload = [];
          window.alert(this.props.t("registerThing.notResponding"));
        }

        this.setState({
          duid: queryParams.duid.toUpperCase(),
          sites: all_sites,
          loading: false,
          customers: c_pload,
          geolocation: geoLocationEnabled,
        });
      }

      if (queryParams.siteGuid) {
        await this.onSiteSelected(queryParams.siteGuid);
      }
    } else {
      window.alert(
        this.props.t("registerThing.invalidId") + "\n" + this.props.t(message)
      );
      this.setState({ redirect: true, loading: false });
    }
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this._watchID);
  }

  updateGeoState(geoResult, pos) {
    const adrinfo = utils.geocodeReverseAddress(geoResult.address);
    this.setState({
      address: adrinfo.city + ", " + adrinfo.country,
      country: adrinfo.country,
      city: adrinfo.city,
      latitude: pos.lat,
      longitude: pos.lng,
      geocode: geoResult.display_name,
    });
  }

  addGeoLocation() {
    if (navigator.geolocation) {
      const t = this;
      if (this._watchID === null)
        this._watchID = navigator.geolocation.getCurrentPosition(
          function (position) {
            const p = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            geoLocation
              .getLocationFromCoordinates(p.lat, p.lng)
              .then((results) => {
                t.updateGeoState(results.data, p);
              });
          },
          function (err) {
            utils.debug("Error: ", err.message);
          }
        );
      return true;
    }
    return false;
  }

  showNewSite() {
    if (this.state.selectedSite && this.state.selectedSite.siteGuid === 0) {
      const customerOptions = this.state.customers.map((customer) => ({
        label: customer.name,
        value: customer.customerGuid,
        name: customer.name,
      }));

      return (
        <>
          <FormGroup>
            {this.state.customers.length > 1 ? (
              <div className="d-flex mb-3 w-100">
                <InputGroupText>{keys.ICON_CUSTOMER}</InputGroupText>
                  <SelectSearch
                    filterOptions={fuzzySearch}
                    options={customerOptions}
                    name="selectedCustomerGuid"
                    placeholder={
                      this.props.t("registerThing.selectCustomer") + "..."
                    }
                    search={true}
                    onChange={this.onCustomerSelected.bind(this)}
                    value={this.state.selectedCustomerGuid}
                    autoComplete="on"
                  />
              </div>
            ) : null}
            <InputGroup className="mb-3">
              <InputGroupText>{keys.ICON_SITE}</InputGroupText>
              <Input
                placeholder={this.props.t("registerThing.siteName")}
                type="text"
                name="sitename"
                value={this.state.sitename}
                onChange={this.onInputChange.bind(this)}
              />
            </InputGroup>

            <div className="mw-100">
              <InputGroup className="mb-3">
                <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                <Input
                  placeholder={this.props.t("registerThing.siteAddress")}
                  type="text"
                  name="geocode"
                  value={this.state.geocode}
                  onChange={this.onInputChange.bind(this)}
                />
              </InputGroup>
              {this.state.geocodeList.length > 0 && (
                <div className="adrsearch-results">
                  {this.state.geocodeList.map((item, k) => {
                    return (
                      <div
                        key={k}
                        className="adrsearch-item"
                        onClick={this.onLocationSelected.bind(this, item)}
                      >
                        {item.display_name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </FormGroup>
        </>
      );
    } else return null;
  }

  render() {
    const { canRegisterNewGateway } = this.state;
    let formHasErrors = false;
    if (this.state.loading) return <Loader />;
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }

    if (this.state.deviceAdded) {
      navigator.geolocation.clearWatch(this._watchID);
      return <Redirect to="/" />;
    }

    // Check to figure out the Register Gateway button state
    if (this.state.gatewayName === "") {
      formHasErrors = true;
    }
    if (this.state.sitename === "") {
      // Site Name missing
      formHasErrors = true;
    }
    if (this.state.selectedSite && this.state.selectedSite.siteGuid === 0) {
      // Creating a new site
      if (
        this.state.geocode === "" ||
        this.state.latitude === "" ||
        this.state.longitude === ""
      ) {
        // Missing location information
        formHasErrors = true;
      }
    }

    // TODO: Uncomment the following lines once Promotional period is ended
    // if (this.state.selectedLicenseName === "") {
    //   formHasErrors = true;
    // }

    var siteOptions = this.state.sites.map((site) => ({
      label: site.name,
      value: site.siteGuid,
      name: site.name,
    }));
    siteOptions.unshift({
      label: this.props.t("registerThing.newSite"),
      value: "0",
      name: this.props.t("registerThing.createNewSite"),
    });

    return (
      <>
        <Col lg="6" md="6">
          <Card className="bg-secondary shadow border-2 mt-md--5 mt-lg-2">
            <CardHeader className="bg-transparent">
              <div className="text-center mb-2">
                {this.props.t("registerThing.yourDeviceId") +
                  ": " +
                  this.state.duid}
              </div>

              <div className="text-center my-2">
                {canRegisterNewGateway ? (
                  <strong>
                    {this.props.t("registerThing.registerIt") + "!"}
                  </strong>
                ) : (
                  <div className="text-warning">
                    <span className="mr-2">{keys.ICON_WARNING}</span>
                    {this.props.t("registerThing.subscriptionRequired")}
                  </div>
                )}
              </div>
            </CardHeader>

            {canRegisterNewGateway && (
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupText>{keys.ICON_GATEWAY}</InputGroupText>
                      <Input
                          placeholder={this.props.t("registerThing.gatewayName")}
                          type="text"
                          name="gatewayName"
                          onChange={this.onInputChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="d-flex mb-3">
                    <InputGroupText>{keys.ICON_SITE}</InputGroupText>
                    <SelectSearch
                        filterOptions={fuzzySearch}
                        options={siteOptions}
                        name="site"
                        placeholder={
                            this.props.t("registerThing.selectOrAddSite") +
                            "..."
                        }
                        search={true}
                        onChange={this.onSiteSelected}
                        value={this.state.selectedSite.siteGuid || ""}
                        autoComplete="on"
                    />
                  </div>
                  {this.showNewSite()}
                  <div className="d-flex flex-row justify-content-center">
                    <Button
                        color="primary"
                        disabled={formHasErrors}
                        type="button"
                        onClick={
                          formHasErrors
                              ? this.notifyFormWithErrors.bind(this)
                              : this.addGatewayClicked
                        }
                        className="d-flex align-items-center"
                    >
    <span className="btn-inner--icon">
      {formHasErrors ? keys.ICON_ERROR : keys.ICON_OK}
    </span>
                      <span className="btn-inner--text ml-2">
      {this.props.t("registerThing.registerDevice")}
    </span>
                    </Button>
                    <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        className="ml-2"
                    >
                      <DropdownToggle
                          tag="span"
                          data-toggle="dropdown"
                      >
                        <Button
                            className="btn-icon"
                            color="secondary"
                            type="button"
                        >
                          <i className="fa fa-question"></i>
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                          right
                          className="shadow-lg"
                          style={{minWidth: '300px', overflowX: 'hidden'}}
                      >
                        <div className="px-3 py-2">
                          <h6 className="text-primary font-weight-bold text-uppercase">
                            Gateway 1-1 (XIG100)
                          </h6>
                          <DropdownItem
                              tag="a"
                              href={keys.XP_QUICK_INSTALLATION_GUIDE_XIG100_URL}
                              target="_blank"
                              className="d-flex justify-content-between align-items-center"
                          >
                            {this.props.t("sidebar.quickGuide")}
                            <i className="fa fa-external-link-alt text-muted ml-auto"></i>
                          </DropdownItem>
                        </div>
                        <div className="px-3 py-2">
                          <h6 className="text-primary font-weight-bold text-uppercase">
                            IPL600
                          </h6>
                          <DropdownItem
                              tag="a"
                              href={keys.XP_QUICK_INSTALLATION_GUIDE_IPL600_URL}
                              target="_blank"
                              className="d-flex justify-content-between align-items-center"
                          >
                            {this.props.t("sidebar.quickGuide")}
                            <i className="fa fa-external-link-alt text-muted ml-auto"></i>
                          </DropdownItem>
                        </div>
                        <DropdownItem divider/>
                        <div className="px-3 py-2">
                          <h6 className="text-primary font-weight-bold text-uppercase">
                            {this.props.t("sidebar.documents")}
                          </h6>
                          <DropdownItem
                              tag="a"
                              href={`${keys.XP_REPORTING_API_URL}/api/supported_devices`}
                              target="_blank"
                              className="d-flex justify-content-between align-items-center"
                          >
                            {this.props.t("sidebar.supportedDevices")}
                            <i className="fa fa-external-link-alt text-muted ml-auto"></i>
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </>
    );
  }
}

export default withTranslation("common")(RegisterThing);
